import { Action, AnyAction } from 'redux';

import { BackLocationDescriptor, NavigationTarget } from 'common/lib/Routing/types';
import { SERVER } from 'common/lib/UniversalTools/serverActionTypes';

export const redirect = (locationDescriptor: NavigationTarget, code = 302): AnyAction => ({
  type: SERVER.REDIRECT,
  locationDescriptor,
  code,
});

export const notFound = (): Action => ({
  type: SERVER.NOT_FOUND,
});

export const gone = (): Action => ({
  type: SERVER.GONE,
});

export const redirectWithReturnPathname = (
  redirectLocationDescriptor: NavigationTarget,
  currentLocationDescriptor: BackLocationDescriptor,
  code = 302
): AnyAction => ({
  ...redirect(redirectLocationDescriptor, code),
  backLocationDescriptor: currentLocationDescriptor,
});
