export const USER_FLAGS = {
  FETCH: {
    REQUEST: 'USER_FLAGS.FETCH.REQUEST',
    SUCCESS: 'USER_FLAGS.FETCH.SUCCESS',
    FAILURE: 'USER_FLAGS.FETCH.FAILURE',
  },
  SET: {
    REQUEST: 'USER_FLAGS.SET.REQUEST',
    SUCCESS: 'USER_FLAGS.SET.SUCCESS',
    FAILURE: 'USER_FLAGS.SET.FAILURE',
  },
  DELETE: {
    REQUEST: 'USER_FLAGS.DELETE.REQUEST',
    SUCCESS: 'USER_FLAGS.DELETE.SUCCESS',
    FAILURE: 'USER_FLAGS.DELETE.FAILURE',
  },
};
