import createReducer from 'common/util/createReducer';

import userNormalizer from 'common/modules/auth/normalizers/user';

import { User } from 'common/modules/auth/types';

import { AUTH } from 'common/modules/auth/actionTypes';

export type State = User | null;

export const initialState: State = null;

export default createReducer<State>(initialState, {
  [AUTH.USER.FETCH.SUCCESS](_state, action) {
    return userNormalizer(action.payload);
  },

  [AUTH.CHANGE_DEFAULT_LANGUAGE.SUCCESS](_state, action) {
    return userNormalizer(action.payload);
  },

  [AUTH.USER.FETCH.FAILURE]() {
    return initialState;
  },

  [AUTH.CURRENT_USER_LOGOUT.SUCCESS]() {
    return initialState;
  },
});
