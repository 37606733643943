import createReducer from 'common/util/createReducer';

import { AUTH } from 'common/modules/auth/actionTypes';
import { USER_FLAGS } from 'common/modules/user-flags/userFlagActionTypes';

type State = {
  isLoading: boolean;
  alreadyFetched: boolean;
};

const initialState: State = {
  isLoading: false,
  alreadyFetched: false,
};

export default createReducer<State>(initialState, {
  [USER_FLAGS.FETCH.REQUEST](state) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [USER_FLAGS.FETCH.SUCCESS]() {
    return {
      isLoading: false,
      alreadyFetched: true,
    };
  },

  [USER_FLAGS.FETCH.FAILURE]() {
    return {
      isLoading: false,
      alreadyFetched: true,
    };
  },

  [AUTH.CURRENT_USER_LOGOUT.SUCCESS]() {
    return initialState;
  },
});
