import { BrowserHistory, MemoryHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore, Middleware } from 'redux';
import createSentryMiddleware from 'redux-sentry-middleware';
import thunkMiddleware from 'redux-thunk';

import { DependencyInjection } from 'common/lib/DependencyInjection/services/services';
import cookieHandlerMiddleware from 'common/lib/SideEffects/cookieHandlerMiddleware';
import createFetchMiddleware from 'common/lib/SideEffects/fetchMiddleware';
import createHistoryMiddleware from 'common/lib/SideEffects/historyMiddleware';
import multiMiddleware from 'common/lib/SideEffects/multiMiddleware';
import { promiseMiddleware } from 'common/lib/SideEffects/promiseMiddleware';
import rerunMiddleware from 'common/lib/SideEffects/rerunMiddleware';
import trackingMiddleware from 'common/lib/Tracking/gtmTrackingMiddleware';
import { config } from 'common/util/configHandler';

import appReducers from 'common/reducers/appReducers';

import ogpMiddleware from 'common/modules/ogp/middleware/ogp';
import GlobalState from 'common/stores/global';

interface Props {
  initialState: Partial<GlobalState>;
  history: MemoryHistory | BrowserHistory;
  di: DependencyInjection;
}

export default function configureStore({ initialState, history, di }: Props) {
  const middlewares: Middleware[] = [
    multiMiddleware,
    thunkMiddleware,
    cookieHandlerMiddleware(di.get('core.cookie')),
    rerunMiddleware,
    ogpMiddleware,
    createHistoryMiddleware(history, di.get('core.location'), di.get('core.locale')),
    promiseMiddleware,
    createFetchMiddleware(di),
    trackingMiddleware,
  ];

  if (config.SENTRY_PUBLIC_KEY && typeof window !== 'undefined' && typeof Sentry !== 'undefined') {
    middlewares.unshift(
      createSentryMiddleware(Sentry, {
        stateTransformer: (state) => {
          const newState = JSON.parse(JSON.stringify(state));
          newState.profile = 'State has been suppressed due to GDPR reasons.';
          if (newState.auth) {
            newState.auth.currentUser = 'State has been suppressed due to GDPR reasons.';
            newState.auth.memorizeEmail = 'State has been suppressed due to GDPR reasons.';
          }
          return JSON.stringify(newState);
        },
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  const toolChain: Function[] =
    typeof __DEBUG__ !== 'undefined' && __DEBUG__
      ? [
          applyMiddleware(...middlewares),
          window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : <T>(f: T) => f,
        ]
      : [applyMiddleware(...middlewares)];

  // Remove keys in intial state that don't have they equivalent reducer
  // registered. Otherwise reducer will throw a error.
  const allowedInitialStateKeys = Object.keys(appReducers);
  const filteredInitialState = allowedInitialStateKeys.reduce((prev, next) => {
    if (initialState[next as keyof GlobalState]) {
      return { ...prev, [next]: initialState[next as keyof GlobalState] };
    }
    return prev;
  }, {});

  // eslint-disable-next-line @typescript-eslint/ban-types
  const store = compose<Function>(...toolChain)(createStore)(combineReducers(appReducers), filteredInitialState);

  // Keep current store reducers in mind when they get replaced later because of the code
  // splitting. This enables us to _not_ remove already registered reducers.
  store.currentReducers = appReducers;

  return store;
}
