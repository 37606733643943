import { combineReducers } from 'redux';

import flags from 'common/modules/user-flags/reducers/flagsReducer';
import loading from 'common/modules/user-flags/reducers/loadingReducer';
import loadingState from 'common/modules/user-flags/reducers/loadingStateReducer';

import { DateWhenFlagWasSet, UserFlagKey } from 'common/modules/user-flags/types';

export type State = {
  flags: { [key in UserFlagKey]?: DateWhenFlagWasSet };
  loading: UserFlagKey[];
  loadingState: {
    isLoading: boolean;
    alreadyFetched: boolean;
  };
};

export default combineReducers<State>({
  flags,
  loading,
  loadingState,
});
