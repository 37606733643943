import { MouseEvent, MouseEventHandler, useCallback } from 'react';

import useDi from 'common/lib/DependencyInjection/useDi';
import { getLinkClickEvent } from 'common/modules/tealium/utils/clickUtils';
import { ClickEventType, LinkClickEvent } from 'common/modules/tealium/utils/tealiumEvents';

const useTealiumLinkClick = (customEventData: Partial<LinkClickEvent> & { event_type: ClickEventType }) => {
  const { track } = useDi().get('core.tealium');

  return useCallback(
    (onClick?: MouseEventHandler<HTMLAnchorElement>) => (event: MouseEvent<HTMLAnchorElement>) => {
      if (event.currentTarget) {
        track(getLinkClickEvent(event.currentTarget, customEventData));
      }
      onClick?.(event);
    },
    [customEventData, track]
  );
};

export default useTealiumLinkClick;
