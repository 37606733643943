import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { close, open } from 'common/modules/modal/actions/modalActions';

import { ModalState } from 'common/modules/modal/types';

interface ModalOpen<TOptions> extends Omit<ModalState, 'key' | 'opener' | 'position'> {
  openClean?: boolean;
  options?: TOptions;
}

interface ModalController<TOptions> {
  closeModal: (fullClose?: boolean) => void;
  isModalOpen: boolean;
  modalOptions?: TOptions;
  openModal: (modalState?: ModalOpen<TOptions>) => void;
}

const useModal = <TOptions>(modalKey: string): ModalController<TOptions> => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);

  const isModalOpen = modal?.key === modalKey;

  const openModal = useCallback(
    (modalState?: ModalOpen<TOptions>) => dispatch(open({ ...modalState, key: modalKey })),
    [dispatch, modalKey]
  );

  const closeModal = useCallback((fullClose?: boolean) => dispatch(close(fullClose)), [dispatch]);

  return {
    closeModal,
    isModalOpen,
    modalOptions: modal?.options,
    openModal,
  };
};

export default useModal;
