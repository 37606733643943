import { ApiItem, User } from 'common/modules/auth/types';

export default function userNormalizer(apiItem: ApiItem): User {
  return {
    audience: apiItem.audience,
    avatarUrl: apiItem.profile_picture_url,
    createdAt: apiItem.created_at,
    email: apiItem.email,
    emailVerified: apiItem.email_address_verified,
    emailValid: apiItem.emails_deliverable,
    language: apiItem.language,
    uuid: apiItem.id,
  };
}
