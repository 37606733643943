import createReducer from 'common/util/createReducer';

import { ModalState } from 'common/modules/modal/types';

import { MODAL } from 'common/modules/modal/modalActionTypes';

export type State = ModalState | null;

export default createReducer<State>(null, {
  [MODAL.OPEN]: (state, { payload: { key, openClean, options, triggerId } }) => ({
    key,
    ...(!!state && !openClean ? { opener: state } : {}),
    options,
    position: state?.position || window?.scrollY,
    ...(!!state?.triggerId ? { triggerId: state.triggerId } : { triggerId }),
  }),
  [MODAL.CLOSE]: (state, { payload }) => (!!state?.opener && !payload ? state.opener : null),
});
