import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useCookieServiceWithCookieKey } from 'common/lib/Cookies/hooks/useCookieServiceWithCookieKey';
import { useQueryParam } from 'common/lib/Routing/hooks/useQuery';

import { setTriggerSource } from 'common/modules/tracking/actions/tracking';

import { TriggerSource } from 'common/modules/tracking/reducers';

const useInitiateTriggerSource = () => {
  const queryFeat = useQueryParam('utm_source');
  const [get, , remove] = useCookieServiceWithCookieKey('trigger_source');
  const dispatch = useDispatch();

  return useCallback(() => {
    const triggerSourceCookie = get();
    let triggerSource: TriggerSource = {};
    if (triggerSourceCookie) {
      try {
        triggerSource = JSON.parse(triggerSourceCookie);
      } catch (error) {
        // no-op
      }
      remove();
    }
    if (triggerSourceCookie || queryFeat) {
      dispatch(setTriggerSource({ ...triggerSource, ...(queryFeat ? { feat: queryFeat } : {}) }));
    }
  }, [dispatch, get, queryFeat, remove]);
};

export default useInitiateTriggerSource;
