import { combineReducers } from 'redux';

import currentUserReducer, { State as CurrentUserState } from 'common/modules/auth/reducers/currentUserReducer';
import notLoggedInUserReducer, {
  State as NotLoggedInUserReducerState,
} from 'common/modules/auth/reducers/notLoggedInUserReducer';

export type State = {
  currentUser: CurrentUserState;
  notLoggedInUser: NotLoggedInUserReducerState;
};

export default combineReducers<State>({
  currentUser: currentUserReducer,
  notLoggedInUser: notLoggedInUserReducer,
});
