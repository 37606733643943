import qs from 'query-string';
import { Location as RRLocation } from 'react-router';

import { BackLocationDescriptor, NavigationTarget, Params, QueryCompat, QueryObject } from 'common/lib/Routing/types';
import { config } from 'common/util/configHandler';
import { Locale, Route } from 'common/util/configTypes';
import { RouteKey } from 'common/util/routeKeys';

const optionalRegex = /\/{\?.*}/;

export const computePath = (route: string, params: Params): string => {
  if (!route) return route;

  let computedPath = route;
  for (const key in params) {
    const paramsValue = params[key];
    // allow optional params like {?PARAM1}
    if ({}.hasOwnProperty.call(params, key) && !!paramsValue) {
      computedPath = computedPath.replace('{?' + key.toUpperCase() + '}', paramsValue);
    }
    // allow required params like {PARAM2}
    if ({}.hasOwnProperty.call(params, key) && !!paramsValue) {
      computedPath = computedPath.replace('{' + key.toUpperCase() + '}', paramsValue);
    }
  }
  // remove all not used optional params
  computedPath = computedPath.replace(optionalRegex, '');
  return computedPath;
};

const getLocalizedRoute = (locationRoute: Route, locale: Locale) => {
  const route = typeof locationRoute === 'object' ? locationRoute[locale] : locationRoute;
  if (route) {
    return { route, locale };
  }
  // TODO CHI-2075
  // Provides fallback for routes that are not supported in the current locale. This should be
  // removed once all locales are fully supported (i.e. after jobup application in German only)
  const fallbackLocale = config.SUPPORTED_LOCALES[0];
  const fallbackRoute = locationRoute[fallbackLocale as keyof typeof locationRoute];

  if (!!fallbackRoute) {
    return { route: fallbackRoute, locale: fallbackLocale };
  }
  throw new Error('Invalid route provided in locationDescriptor.');
};

const getLocalizedPath = (
  location: NavigationTarget,
  locale: Locale,
  options: { defaultLocale: Locale; defaultParameters: any }
): string | undefined => {
  const { defaultLocale, defaultParameters } = options;
  if ('pathname' in location) {
    return location.pathname;
  }
  if (location.route) {
    const params = location.params || {};
    const selectedLocale = locale || defaultLocale;

    if (!selectedLocale) {
      throw new Error('Neither a defaultLocale or a locale with the locationDescriptor has been provided.');
    }

    const { route, locale: definitiveLocale } = getLocalizedRoute(location.route, selectedLocale);
    return computePath(route, { ...defaultParameters, ...params, locale: definitiveLocale });
  }
};

// this can compete in ugliness with this: https://www.clubfurniture.com/products/harmon-arched-back-leather-grand-scale-sofa-w-nailhead-trim
const localizationOptions = {
  defaultLocale: config.SUPPORTED_LOCALES[0],
  defaultParameters: config,
};

const searchRegExp = /\+/g;

const resolveQueryAndHash = (query?: QueryCompat | URLSearchParams, hash?: string): string => {
  const urlSearchParams = query && (query instanceof URLSearchParams ? query : queryToUrlSearchParams(query));
  // replace can be replaced in the future with replaceAll which is much nicer to look at
  const search = urlSearchParams?.toString().replace(searchRegExp, '%20');
  const searchPath = !!search ? '?' + search : '';
  const hashPath = !!hash ? '#' + hash : '';
  return searchPath + hashPath;
};

export const getFullPath = (to: NavigationTarget, locale: Locale) => {
  const resolvedQueryAndHash = resolveQueryAndHash(to.query, to.hash);
  if ('pathname' in to) {
    return to.pathname + resolvedQueryAndHash;
  }
  const localizedPath = getLocalizedPath(to, locale, localizationOptions) || '';
  return localizedPath + resolvedQueryAndHash;
};

const href = (route: Route, locale: Locale, params?: Params): string =>
  getFullPath(
    {
      route,
      params,
    },
    locale
  );

/**
 * @deprecated
 * please use getFullPath for NavigationTarget or location.toString() for Location
 */
export const locationDescriptorToPath = (locationDescriptor: NavigationTarget | RRLocation, locale: Locale): string => {
  let path: string;
  if ('route' in locationDescriptor) {
    if (typeof locationDescriptor.route === 'object') {
      // to clean up the router / state / custom locationDescriptor problem there is a ticket: ICTBLG-7234
      path = computePath(locationDescriptor.route[locale], locationDescriptor.params || {});
    } else {
      path = computePath(locationDescriptor.route, locationDescriptor.params || {});
    }
  } else {
    path = locationDescriptor.pathname;
  }
  if ('search' in locationDescriptor) {
    path = `${path || ''}${locationDescriptor.search}`;
  } else if (locationDescriptor.query && Object.keys(locationDescriptor.query).length) {
    const search = qs.stringify(locationDescriptor.query);
    if (search.length) {
      path = `${path || ''}?${search}`;
    }
  }
  return path || '';
};

export const createRedirectBackPath = (
  redirectLocation: NavigationTarget,
  backLocation: BackLocationDescriptor | undefined,
  locale: Locale
): string => {
  if (!backLocation || Object.keys(backLocation).length === 0) {
    return getFullPath(redirectLocation, locale);
  }
  const redirectQuery = redirectLocation.query || {};
  const backQuery = flattenBackLocation(backLocation);

  return getFullPath(
    {
      ...redirectLocation,
      query: {
        ...redirectQuery,
        ...backQuery,
      },
    },
    locale
  );
};

export const flattenBackLocation = (backLocation?: BackLocationDescriptor) => {
  const backQuery: { _rk?: string; _rp?: string; _rq?: string } = {};

  if (backLocation) {
    backQuery._rk = backLocation.routeKey.toLowerCase();
    if (backLocation.params) {
      backQuery._rp = qs.stringify(backLocation.params);
    }
    if (backLocation.query) {
      backQuery._rq = qs.stringify(backLocation.query);
    }
  }
  return backQuery;
};

export const getRouteKeyFromQuery = (query: QueryObject) => (query._rk as string)?.toUpperCase() as RouteKey;

export const removeFromQuery = (query: QueryObject = {}, keys: Array<string> = []): QueryObject =>
  Object.keys(query)
    .filter((key) => keys.indexOf(key) === -1)
    .reduce((prev, key) => ({ ...prev, [key]: query[key] }), {});

/**
 * ensure that pathname is a absolute URL
 * @param {String} pathname A relative or absolute path
 * @return {String} absolute pathname
 */
export const ensureAbsolutePath = (pathname: string): string =>
  /^https?:/.test(pathname)
    ? pathname
    : `${config.INTERNAL_LINK_GEN_PROTOCOL}://${config.HOST}${
        pathname && pathname.startsWith('/') ? '' : '/'
      }${pathname}`;

const queryToUrlSearchParams = (query: QueryCompat): URLSearchParams => {
  const params = new URLSearchParams();
  Object.keys(query)
    .sort()
    .forEach((key) => {
      const value = query[key];
      if (Array.isArray(value)) {
        value.forEach((v) => params.append(key, v));
      } else {
        if (value !== undefined && value !== null) {
          params.set(key, value);
        }
      }
    });
  return params;
};

export default href;

export const getFullUrl = (pathname = '', encoded = true) => {
  const url = pathname.startsWith('http') ? pathname : `${config.PROTOCOL}://${config.HOST}${pathname}`;

  return encoded ? encodeURIComponent(url) : url;
};

export const appendQueryParamToUrl = (url: string, queryParam: string) =>
  `${url}${url.includes('?') ? '&' : '?'}${queryParam}`;

export const removeQueryParametersStartingWith = (queryString: string, prefixes: string[]) => {
  const params = new URLSearchParams(queryString);

  Array.from(params.keys()).forEach((key) => prefixes.some((prefix) => key.startsWith(prefix)) && params.delete(key));

  const stringifiedParams = params.toString();

  return stringifiedParams.length > 0 ? `?${stringifiedParams}` : '';
};

export const removeSearchParam = (searchParam: string, location: Location): string => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete(searchParam);
  const searchParamsString = searchParams.toString();
  return location.pathname + (!!searchParamsString ? '?' + searchParamsString : '');
};
