import { routes } from 'common/util/configHandler';
import { ConfigurationRoutes } from 'common/util/configTypes';
import { RouteKey } from 'common/util/routeKeys';

type RecursiveRoutesMap =
  | RouteKey
  | {
      [pathSegment: string]: RecursiveRoutesMap;
    };

const removeLeadingAndTrailingSlashes = (path: string) => path.replace(/^\/|\/$/g, '');

const isObject = (object: unknown) => object && typeof object === 'object';

const mergeRouteMaps = (target: RecursiveRoutesMap, source: RecursiveRoutesMap) => {
  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = (target as Record<string, RecursiveRoutesMap>)[key];
    const sourceValue = (source as Record<string, RecursiveRoutesMap>)[key];

    if (isObject(targetValue) && isObject(sourceValue)) {
      (target as Record<string, RecursiveRoutesMap>)[key] = mergeRouteMaps(Object.assign({}, targetValue), sourceValue);
    } else {
      (target as Record<string, RecursiveRoutesMap>)[key] = sourceValue;
    }
  });

  return target;
};

export const getRoutesMap = (routes: Partial<ConfigurationRoutes>): RecursiveRoutesMap =>
  Object.entries(routes).reduce<RecursiveRoutesMap>((routesMap, [routeKey, route]) => {
    if (typeof route === 'string') {
      return routesMap;
    }

    return Object.values(route).reduce((routesMap, path) => {
      if (!path || path.startsWith('http')) {
        return routesMap;
      }

      const routeMap = removeLeadingAndTrailingSlashes(path)
        .split('/')
        .reverse()
        .reduce<RecursiveRoutesMap>((all, segment, index) => {
          if (!segment) {
            throw new Error('Path cannot contain empty segments (double //)');
          }
          return {
            [segment.startsWith('{?') ? '__optional__' : segment.startsWith('{') ? '__placeholder__' : segment]:
              index === 0 ? { __index__: routeKey as RouteKey } : all,
          };
        }, {});
      return mergeRouteMaps(routesMap, routeMap);
    }, routesMap);
  }, {});

const globalRoutesMap = getRoutesMap(routes);

const recursiveLookup = (segmentedPath: string[], routesMap?: RecursiveRoutesMap): RecursiveRoutesMap | undefined => {
  if (!routesMap || typeof routesMap === 'string') {
    return routesMap;
  }
  if (!segmentedPath.length) {
    return routesMap.__index__ || recursiveLookup([], routesMap.__optional__);
  }
  const [current, ...rest] = segmentedPath;
  return (
    recursiveLookup(rest, routesMap[current]) ||
    recursiveLookup(rest, routesMap.__placeholder__) ||
    recursiveLookup(rest, routesMap.__optional__)
  );
};

export const reverseLookupRoute = (path?: string, routesMap: RecursiveRoutesMap = globalRoutesMap) => {
  if (!path) {
    return;
  }

  const segmentedPath = removeLeadingAndTrailingSlashes(path).split('/');
  return recursiveLookup(segmentedPath, routesMap) as RouteKey;
};
