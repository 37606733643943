import { useMemo } from 'react';

import { ClientCookieOptions } from 'common/lib/Cookies/classes/ClientCookieService';
import { CookieKey } from 'common/lib/Cookies/types';
import useDi from 'common/lib/DependencyInjection/useDi';

type HookStruct = [() => string | undefined, (value: string, options?: ClientCookieOptions) => void, () => void];

export const useCookieServiceWithCookieKey = (cookieKey: CookieKey): HookStruct => {
  const cookieService = useDi().get('core.cookie');

  return useMemo(
    () => [
      () => cookieService.get(cookieKey),
      (value: string, options?: ClientCookieOptions) => cookieService.set(cookieKey, value, options),
      () => cookieService.remove(cookieKey),
    ],
    [cookieService, cookieKey]
  );
};
