import { ClickEventType, LinkClickEvent } from 'common/modules/tealium/utils/tealiumEvents';

export const getLinkClickEvent = (
  link: HTMLAnchorElement,
  customEventData: Partial<LinkClickEvent> & { event_type: ClickEventType }
): LinkClickEvent => {
  const { feature, path } = getComponentInfo(link);

  return {
    event_component_path: path,
    event_component_type: feature,
    event_link_text: link.textContent || link.getAttribute('title') || '',
    event_name: 'click',
    event_position: '',
    event_target_url_full: link.href,
    event_value: '',
    ...customEventData,
  };
};

export const getComponentInfo = (el: Element | null, feature = '', path = ''): { feature: string; path: string } => {
  if (el && el.nodeName !== 'HTML') {
    if (el.hasAttribute('data-feat')) {
      feature = `${el.getAttribute('data-feat')}${feature ? `_${feature}` : feature}`;
    }
    let designator = el.nodeName.toLowerCase();
    if (el.hasAttribute('id') && el.id) {
      designator = `${designator}#${el.id}`;
    } else if (el.getAttribute('data-cy')) {
      designator = `${designator}*${el.getAttribute('data-cy')}`;
    }
    return getComponentInfo(el.parentElement, feature, `${designator} ${path}`);
  }
  return { feature, path: path.trim() };
};
