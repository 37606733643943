import { AnyAction } from 'redux';

import createReducer from 'common/util/createReducer';

import { UserFlagKey } from 'common/modules/user-flags/types';

import { USER_FLAGS } from 'common/modules/user-flags/userFlagActionTypes';

type State = UserFlagKey[];

const initialState: State = [];

export default createReducer<State>(initialState, {
  [USER_FLAGS.SET.REQUEST](state, action: AnyAction) {
    return [...state, action.flag];
  },

  [USER_FLAGS.SET.SUCCESS](state, action: AnyAction) {
    return state.filter((flag) => flag !== action.flag);
  },

  [USER_FLAGS.SET.FAILURE](state, action: AnyAction) {
    return state.filter((flag) => flag !== action.flag);
  },

  [USER_FLAGS.DELETE.REQUEST](state, action: AnyAction) {
    return [...state, action.flag];
  },

  [USER_FLAGS.DELETE.SUCCESS](state, action: AnyAction) {
    return state.filter((flag) => flag !== action.flag);
  },

  [USER_FLAGS.DELETE.FAILURE](state, action: AnyAction) {
    return state.filter((flag) => flag !== action.flag);
  },
});
