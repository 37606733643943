import localization, { LocalizationState } from 'common/lib/Localization/localizationReducer';
import server from 'common/lib/UniversalTools/serverReducer';
import createReducer from 'common/util/createReducer';

import prevent, { State as PreventState } from 'common/lib/SideEffects/prevent/reducers/preventReducer';
import auth, { State as AuthState } from 'common/modules/auth/reducers/authReducer';
import featureFlags, { State as FeatureFlagsState } from 'common/modules/feature-flags/reducers/featureFlagReducer';
import localFlags from 'common/modules/local-flags/reducers/localFlagPersistReducer';
import { State as LocalFlagState } from 'common/modules/local-flags/reducers/localFlagReducer';
import minimalUser, { State as MinimalUserState } from 'common/modules/minimal-user/reducers/minimalUserReducer';
import modal, { State as ModalState } from 'common/modules/modal/reducers/modalReducer';
import persisted, { State as PersistedState } from 'common/modules/redux-persist/reducers/persistedReducer';
import snackbar, { State as SnackbarState } from 'common/modules/snackbar/reducers/snackbarReducer';
import userFlags, { State as UserFlagsState } from 'common/modules/user-flags/reducers/userFlagsReducer';

import { BotState } from 'common/modules/bot-detect/types';
import { CaptchaState } from 'common/modules/captcha/types';
import { Device } from 'common/modules/mobile-detect/types';

import ogp, { State as OgpState } from 'common/modules/ogp/reducers';
import search, { State as SearchState } from 'common/modules/search/reducers';
import seo, { SeoState } from 'common/modules/seo/reducers';
import tracking, { State as TrackingState } from 'common/modules/tracking/reducers';

const mobileDetect = createReducer({ isMobile: false, os: null }, {});
const botDetect = createReducer({ clientClassification: 'human' }, {});
const captcha = createReducer(null, {});

export type AppReducers = {
  auth: AuthState;
  botDetect: BotState;
  captcha: CaptchaState;
  featureFlags: FeatureFlagsState;
  localFlags: LocalFlagState;
  localization: LocalizationState;
  minimalUser: MinimalUserState;
  mobileDetect: Device;
  modal: ModalState;
  ogp: OgpState;
  persisted: PersistedState;
  prevent: PreventState;
  search: SearchState;
  seo: SeoState;
  server: {}; // TODO: TSify
  snackbar: SnackbarState;
  tracking: TrackingState;
  userFlags: UserFlagsState;
};

// List of site-wide mandatory reducers.
const appReducers = {
  auth,
  botDetect,
  captcha,
  featureFlags,
  localFlags,
  localization,
  minimalUser,
  mobileDetect,
  modal,
  ogp,
  persisted,
  prevent,
  search,
  seo,
  server,
  snackbar,
  tracking,
  userFlags,
};

export default appReducers;
