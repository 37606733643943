export const AUTH = {
  CHANGE_DEFAULT_LANGUAGE: {
    REQUEST: 'AUTH.CHANGE_DEFAULT_LANGUAGE.REQUEST',
    SUCCESS: 'AUTH.CHANGE_DEFAULT_LANGUAGE.SUCCESS',
    FAILURE: 'AUTH.CHANGE_DEFAULT_LANGUAGE.FAILURE',
  },
  USER: {
    FETCH: {
      REQUEST: 'AUTH.USER.FETCH.REQUEST',
      SUCCESS: 'AUTH.USER.FETCH.SUCCESS',
      FAILURE: 'AUTH.USER.FETCH.FAILURE',
    },
  },
  CURRENT_USER_LOGOUT: {
    REQUEST: 'AUTH.CURRENT_USER_LOGOUT.REQUEST',
    SUCCESS: 'AUTH.CURRENT_USER_LOGOUT.SUCCESS',
    FAILURE: 'AUTH.CURRENT_USER_LOGOUT.FAILURE',
  },
  NOT_LOGGED_IN_USER_ID: 'AUTH.NOT_LOGGED_IN_USER.ID',
};
