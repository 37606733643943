import { TRACKING } from 'common/modules/tracking/actionTypes';
import { TriggerSource } from 'common/modules/tracking/reducers';

export const removeContentTags = () => ({
  type: TRACKING.REMOVE_CONTENT_TAGS,
});

export const setContentTags = (contentTags: string[]) => ({
  type: TRACKING.SET_CONTENT_TAGS,
  payload: contentTags,
});

export const setSearchId = (id: string) => ({
  type: TRACKING.SET_SEARCH_ID,
  payload: id,
});

export const setTriggerSource = (triggerSource: TriggerSource | null) => ({
  type: TRACKING.SET_TRIGGER_SOURCE,
  payload: triggerSource,
});
