import { QueryClient } from '@tanstack/react-query';

import Unknown from 'common/lib/Validators/Unknown/Unknown';

export const createReactQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        retry: (failureCount: number, error: unknown) => {
          const statusCode = Unknown.of(error).get('statusCode').getNumber();
          return statusCode && statusCode >= 423 ? failureCount < 2 : false;
        },
        retryOnMount: false,
        staleTime: 60 * 60 * 1000,
      },
    },
  });
