import createReducer from 'common/util/createReducer';

import { AUTH } from 'common/modules/auth/actionTypes';

export type State = string | null;

const initialState: State = null;

export default createReducer<State>(initialState, {
  [AUTH.NOT_LOGGED_IN_USER_ID](state, action) {
    return action.payload;
  },
});
