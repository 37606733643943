import { styled } from 'panda/jsx';

export const Article = styled('article');

export const Aside = styled('aside');

export const Dialog = styled('dialog');

export const Footer = styled('footer');

export const Header = styled('header');

export const Main = styled('main');

export const Nav = styled('nav');

export const Section = styled('section');
