import { MouseEvent, MouseEventHandler, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useDi from 'common/lib/DependencyInjection/useDi';
import { getFeatFromElement } from 'common/lib/Tracking/TriggerSource/triggerSourceUtils';

import useConsent from 'common/lib/Cookies/hooks/useConsent';
import { useCookieServiceWithCookieKey } from 'common/lib/Cookies/hooks/useCookieServiceWithCookieKey';

import { setTriggerSource } from 'common/modules/tracking/actions/tracking';

export const useTriggerSource = () => {
  const routeKey = useDi().get('core.location').getRouteKey();
  const [, set] = useCookieServiceWithCookieKey('trigger_source');
  const { getHasCategoryConsent } = useConsent();
  const dispatch = useDispatch();

  return useCallback(
    (onClick?: MouseEventHandler) => (event?: MouseEvent, triggerFeat?: string) => {
      if (event?.currentTarget || triggerFeat) {
        const triggerSource = {
          feat: event?.currentTarget ? getFeatFromElement(event.currentTarget.closest('[data-feat]')) : triggerFeat,
          route: routeKey,
        };
        if (getHasCategoryConsent('search')) {
          set(JSON.stringify(triggerSource));
        } else {
          dispatch(setTriggerSource(triggerSource));
        }
      }
      event && onClick?.(event);
    },
    [dispatch, getHasCategoryConsent, routeKey, set]
  );
};

export const useSoftTriggerSource = () => {
  const routeKey = useDi().get('core.location').getRouteKey();
  const dispatch = useDispatch();

  return useCallback(
    (event: MouseEvent) => {
      if (event.currentTarget) {
        dispatch(
          setTriggerSource({
            feat: getFeatFromElement(event.currentTarget.closest('[data-feat]')),
            route: routeKey,
          })
        );
      }
    },
    [dispatch, routeKey]
  );
};
