import createReducer from 'common/util/createReducer';

import { FeatureFlagKey } from 'common/modules/feature-flags/types';

export type State = FeatureFlagKey[];

const featureFlagReducer = createReducer<State>([], {
  'FEATURE_FLAG.ADD'(state, action) {
    return [...state, action.payload];
  },
  'FEATURE_FLAG.REMOVE'(state, action) {
    return state.filter((key) => key !== action.payload);
  },
});

export default featureFlagReducer;
