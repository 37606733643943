import createReducer from 'common/util/createReducer';
import omit from 'common/util/omit';

import { DateWhenFlagWasSet, UserFlagKey, UserFlagsGetResponse } from 'common/modules/user-flags/types';

import { USER_FLAGS } from 'common/modules/user-flags/userFlagActionTypes';

type State = {
  [flag in UserFlagKey]?: DateWhenFlagWasSet;
};

const initialState: State = {};

export default createReducer<State>(initialState, {
  [USER_FLAGS.FETCH.SUCCESS](state, action: UserFlagsGetResponse) {
    return action.payload.reduce((prev, item) => ({ ...prev, [item.flag]: item.created_at }), {});
  },

  [USER_FLAGS.FETCH.FAILURE]() {
    return {};
  },

  [USER_FLAGS.SET.SUCCESS](state, action) {
    return { ...state, [action.flag]: new Date().toISOString() };
  },

  [USER_FLAGS.DELETE.SUCCESS](state, action) {
    return omit(action.flag, state);
  },
});
