import { REHYDRATE } from 'redux-persist';

import createReducer from 'common/util/createReducer';

import { LOCAL_FLAG } from 'common/modules/local-flags/localFlagActionTypes';

//TODO: FIAP-519 fix this any... should be replaced with a boolean | string, refactor localFlagsSelectors wich then will have some ts errors
export type Flags = Record<string, any>;

export interface NameSpaces {
  [key: string]: Flags;
}

export interface State {
  version: string;
  flags: NameSpaces;
}

const initialState = {
  version: '1.0.0',
  flags: {
    default: {},
  },
};

export const localFlagReducer = createReducer<State>(initialState, {
  [REHYDRATE](state, action) {
    const incoming = action.payload?.localFlags;
    if (incoming && incoming.version === '1.0.0') {
      return { ...state, ...incoming };
    }
    return state;
  },

  [LOCAL_FLAG.SET](state, action) {
    const userId = action.userId || 'default';
    return {
      ...state,
      flags: {
        ...state.flags,
        [userId]: {
          ...(state.flags[userId] || {}),
          [action.token]: action.value,
        },
      },
    };
  },

  [LOCAL_FLAG.UNSET](state, action) {
    const userId = action.userId || 'default';
    const newState = {
      ...(state.flags[userId] || {}),
    };
    delete newState[action.token];
    return {
      ...state,
      flags: {
        ...state.flags,
        [userId]: newState,
      },
    };
  },
});
